import React, { Component } from 'react';
import '../styles/commonStyles.css'
import '../styles/adminPage.css';
import Configure from './Configure';
import Reports from './Reports';
import api from '../api';

const style = {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/background.png'})`,
    height: 200,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '0 10vw'
}
class AdminPage extends Component {
    state = { active: 'element', logged: false }
    componentDidMount() {
        this.authorize()
    }
    authorize = async () => {
        try {
            var token = window.localStorage.getItem('token');
            const user = await api({
                url: `/api/Authorize?token=${token}`
            })
            this.setState({ logged: true, user })
        } catch (error) {
            console.log(error)
            window.localStorage.removeItem('token')
            window.location.href = '/login'
        }
    }
    ElementConfigures = [
        {
            name: 'Element',
            label: 'Element',
            className: 'bigger',

        },
        {
            name: 'VeryLow',
            label: 'Mycket låg',
            type: 'number',
            step: 0.01,

        },
        {
            name: 'Low',
            label: 'Låg',
            type: 'number',
            step: 0.01,
        },
        {
            name: 'LittleLow',
            label: 'Något låg',
            type: 'number',
            step: 0.01,
        },
        {
            name: 'OK',
            label: 'OK',
            type: 'number',
            step: 0.01,
        },
        {
            name: 'High',
            label: 'Hög',
            type: 'number',
            step: 0.01,
        },
    ]

    CommentConfigures = [
        {
            name: 'Element',
            label: 'Element',
            className: 'bigger'
        },
        {
            name: 'VeryLowComment',
            label: 'Mycket låg',
            className: 'bigger'
        },
        {
            name: 'LowComment',
            label: 'Låg',
            className: 'bigger'
        },
        {
            name: 'LittleLowComment',
            label: 'Något låg',
            className: 'bigger'
        },
        {
            name: 'OkComment',
            label: 'OK',
            type: 'textarea',
            className: 'bigger'
        },
        {
            name: 'HighComment',
            label: 'Hög',
            className: 'bigger'
        },
    ]

    render() {
        const { active, user = {}, logged } = this.state;
        return logged ? (
            <div>
                <div style={style} className='flexBetween horArrange'>
                    <img src={process.env.PUBLIC_URL + '/images/logo.svg'} alt="loading" className='logo-pic' />
                    <h1>{user.Email}</h1>
                </div>
                <div className='flexHor' style={{ padding: '0 10vw' }}>
                    <div className='col-md-2'>
                        <div className='menu-item background-primary-color' onClick={() => this.setState({ active: 'element' })}>Element</div>
                        <div className='menu-item background-primary-color' onClick={() => this.setState({ active: 'comment' })}>Comment</div>
                        <div className='menu-item background-primary-color' onClick={() => this.setState({ active: 'reports' })}>Reports</div>
                        <div className='menu-item background-primary-color' onClick={() => { window.localStorage.removeItem('token'); window.location.href = '/login' }}>Logout</div>
                    </div>
                    <div style={{ minWidth: 1400 }} className='col-md-10'>
                        {active === 'element' && <Configure type='Element' columns={this.ElementConfigures} />}
                        {active === 'comment' && <Configure type='Comment' columns={this.CommentConfigures} />}
                        {active === 'reports' && <Reports />}
                    </div>
                </div>
            </div >
        ) : null;
    }
}

export default AdminPage;