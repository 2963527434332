import React, { Component } from 'react';
import api from '../api';
import moment from 'moment';
import iconV from 'iconv-lite';

const columns = [
  {
    name: 'Selection',
    labelRender: (onClick, checked) => <div onClick={onClick} className='horArrange pointer'><input style={{ width: 'auto', height: 'auto', margin: '0 10px 0 0' }} type='checkbox' checked={checked} />Select All</div>,
    style: {
      width: 150,
    },
    render: (i, onClick, extra) => <input type='checkbox' checked={extra} onClick={onClick} />
  },
  {
    name: 'Ip',
    label: 'IP',
    style: {
      width: 200,
    },
    render: (d) => <span>{d}</span>
  },
  {
    name: 'Ua',
    label: 'User-Agent',
    style: {
      flex: 1
    },
    render: (d) => <span>{d}</span>
  },
  {
    name: 'CreatedAt',
    label: 'CreatedAt',
    style: {
      width: 200,
    },
    render: (d) => <span>{moment(d).format('YYYY-MM-DD HH:mm:ss')}</span>
  },
  {
    name: 'data',
    label: 'Details',
    style: {
      width: 80,
    },
    render: (i, onClick) => <a onClick={onClick} className='pointer'>View</a>
  },
]

const reportColumns = [
  {
    name: 'Element',
    label: 'Element',
    style: {
      width: 200,
    }
  },
  {
    name: 'Value',
    label: 'g/kg ts',
    style: {
      width: 100,
    }
  },
  {
    name: 'Comment',
    label: 'Comments',
    style: {
      flex: 1,
    }
  },
]

class Reports extends Component {

  state = {
    from: moment().add(-1, 'day').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD')
  }

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const { from, to } = this.state;
    try {
      const items = await api({
        url: `/api/Report`,
        method: 'post',
        body: {
          from: moment(from).format('YYYY-MM-DD'),
          to: moment(to).format('YYYY-MM-DD')
        }
      }) || []
      this.setState({ items })
    } catch (error) {
      console.log(error);
    }

  }

  download = async () => {
    let content = [`IP,User-Agent,CreatedAt,Element,g/kg ts,Comments`];
    const { items = [], selected = [] } = this.state;
    const downloaded = items.filter(i => selected.indexOf(i.CreatedAt) >= 0)
    for (const item of downloaded) {
      content.push(`${item.Ip},"${(item.Ua || '')}",${item.CreatedAt},,,`)
      for (const d of item.data) {
        content.push(`,,,${d.Element},${d.Value},${d.Comment || ''}`)
      }
    }
    content = content.join('\n');
    content = '\uFEFF' + iconV.decode(content, 'utf8')
    content = iconV.encode(content, 'utf8')
    const fileName = `${moment().format('YYYY-MM-DD HH-mm-ss')} - reports.csv`
    const mimeType = 'text/csv;charset=utf-8';
    console.log(fileName)
    var a = document.createElement('a');
    if (navigator.msSaveBlob) { // IE10
      navigator.msSaveBlob(new Blob([content], {
        type: mimeType
      }), fileName);
    } else if (URL && 'download' in a) { //html5 A[download]
      a.href = URL.createObjectURL(new Blob([content], {
        type: mimeType
      }));
      a.setAttribute('download', decodeURIComponent(fileName));
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
  }

  onExpand = (i) => {
    const { expanded = [] } = this.state;
    if (expanded.indexOf(i) < 0) {
      expanded.push(i);
    }
    this.setState({ expanded });
  }

  onSelectAll = () => {
    const checkAll = !this.state.checkAll;
    this.setState({ checkAll: checkAll, selected: checkAll ? this.state.items.map(c => c.CreatedAt) : [] })
  }

  onSelect = (v) => {
    const { selected = [] } = this.state;
    const i = selected.indexOf(v);
    if (i >= 0) {
      selected.splice(i, 1)
    } else {
      selected.push(v)
    }
    this.setState({
      selected
    })
  }


  render() {
    const { from, to, items = [], expanded = [], checkAll = false, selected = [] } = this.state;
    console.log(selected)
    return (
      <div>
        <div style={{ padding: 24 }} className='alignCenter flexBetween'>
          <div>
            <label style={{ paddingRight: 24 }}>From Date</label>
            <input value={from} onChange={v => this.setState({ from: v.target.value })} type='date' name='from' />
          </div>
          <div>
            <label style={{ paddingRight: 24 }}>To Date</label>
            <input value={to} onChange={v => this.setState({ from: v.target.value })} type='date' name='to' />
          </div>
          <button onClick={this.load} style={{ width: 200, fontSize: '2rem' }} className="btn btn-primary">Search</button>
          <button onClick={this.download} style={{ width: 200, fontSize: '2rem' }} className="btn btn-primary">Download</button>
        </div>
        <div className='flexLayout line-item'>
          {
            columns.map(c => (<div style={c.style} key={c.name} className={`title`}>{c.label || c.labelRender(this.onSelectAll, checkAll)}</div>))
          }
        </div>
        <div>
          {
            items.map((d, i) =>
              <div key={i} style={{ borderBottom: '1px solid #ccc', paddingBottom: 10 }}>
                <div onClick={() => this.onExpand(i)} style={{ padding: 10 }} className='flexLayout'>
                  {columns.map(c => <div style={c.style}>{c.render(d[c.name], (e) => {
                    if (c.name === 'data') {
                      this.onExpand(i);
                    } else if (c.name === 'Selection') {
                      e.stopPropagation();
                      this.onSelect(d.CreatedAt);
                    }
                  }, selected.indexOf(d.CreatedAt) >= 0)}</div>)}
                </div>
                <div className='justifyCenter'>
                  {expanded.indexOf(i) >= 0 &&
                    <div style={{ width: '80%', backgroundColor: 'white', border: '1px solid #ccc', padding: 20 }}>
                      <div style={{ padding: 12, fontWeight: 600 }} className='flexLayout'>{reportColumns.map(r => <div style={r.style}>{r.label}</div>)}</div>
                      {
                        (d.data || []).map(a => <div style={{ padding: 12, }} className='report-row flexLayout'>{reportColumns.map(r => <div style={r.style}>{a[r.name]}</div>)}</div>)
                      }
                    </div>
                  }
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default Reports;