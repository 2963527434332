// eslint-disable-next-line import/no-anonymous-default-export
export default (obj) => {
    return new Promise(async (resolve, reject) => {
        window.fetch(`${obj.url}`, {
            method: obj.method,
            headers: new Headers({ 'content-type': 'application/json' }),
            body: obj.body ? JSON.stringify(obj.body) : undefined
        }).then(response => {
            if (response.status !== 200) {
                response.json().then(reject)
                return
            }
            response.json().then(resolve)
        }).catch(e => reject(e))
    })
}