import React, { PureComponent } from "react";
import '../styles/tableForm.css';
import '../styles/commonStyles.css'
import { debounce } from '../helper';
import api from '../api';

const options = [{ name: '', className: 'normal header-color' }, { name: 'g/kg ts', className: 'normal header-color' }, { name: 'Kommentarer', className: 'header-color' }]
const suboptions = [{ name: '', className: 'normal header-color' }, { name: 'mg/kg ts', className: 'normal header-color' }, { name: 'Kommentarer', className: 'header-color' }]

class TableForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            elementOptions: [],
            lists: []
        }
    }

    componentDidMount() {
        this.load()
    }
    load = async () => {
        try {
            const elementOptions = await api({
                url: '/api/Calculate'
            }) || [];
            this.setState({ lists: elementOptions.map(e => ({ Element: e, Value: 0 })) })
        } catch (e) {
            this.setState({ error: e.message || 'Något fel inträffade', loading: false })
            setTimeout(() => {
                this.setState({ error: '' })
            }, 3000);
        }

    }


    onAdd = () => {
        const lists = [...this.state.lists];
        lists.push({ Element: this.state.elementOptions[0], Value: 0 })
        this.setState({ lists })
    }

    onDelete = (index) => {
        const lists = [...this.state.lists];
        lists.splice(index, 1)
        this.setState({ lists })
    }

    handleChange = (e, index, type) => {
        const lists = [...this.state.lists];
        lists[index][type] = Number(e.target.value) >= 0 ? e.target.value : 0;
        this.setState({ lists })
    }

    onChange = (index, e) => {
        const lists = [...this.state.lists];
        lists[index].Element = e.target.value
        this.setState({ lists })
    }

    onCalculation = debounce(async () => {
        const { PAL, KAL, lists } = this.state;
        this.setState({ loading: true, })
        try {
            const results = await api({
                url: '/api/Calculate',
                method: 'post',
                body: { CropItems: lists }
            })
            this.setState({ loading: false, results })
        } catch (e) {
            this.setState({ error: e.message || 'Något fel inträffade', loading: false })
            setTimeout(() => {
                this.setState({ error: '' })
            }, 3000);
        }
    }, 1000)

    render() {
        const { results = [], loading, error } = this.state;

        return (
            <div className='form-contain'>
                <div className='loading'>{loading ? 'Läser in...' : ''}</div>
                {error && <div className='loading' style={{ color: 'red' }}>{error}</div>}
                <div className='form-body white-back-color primary-border flexBetween'>
                    <table>
                        <thead>
                            <tr>
                                {options.map((item, index) => {
                                    return (
                                        <th className={item.className} style={index === 0 ? { fontSize: 20 } : { fontSize: 18 }} key={item.name + '_' + index}>
                                            <div>{item.name}</div>
                                            <div>{item.secondName}</div>
                                        </th>
                                    )
                                })}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.lists.map((item, index) => {
                                return (
                                    <>
                                        {item.Element === 'Koppar' &&
                                            <tr>
                                                {suboptions.map((item, index) => {
                                                    return (
                                                        <th className={item.className} style={index === 0 ? { fontSize: 20 } : { fontSize: 18 }} key={item.name + '__' + index}>
                                                            <div>{item.name}</div>
                                                            <div>{item.secondName}</div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        }
                                        <tr key={index + '_' + item.Element}>
                                            <td>
                                                <div>
                                                    <span>{item.Element}</span>
                                                </div>
                                            </td>
                                            <td >
                                                <div className='table-input-con' style={{ display: 'inline-block' }}>
                                                    <input className='table-input'
                                                        title='Decimaltecken skrivs med punkt'
                                                        onFocus={(e) => {
                                                            e.target.select()
                                                        }}
                                                        type='number'
                                                        onChange={(e) => this.handleChange(e, index, 'Value')} value={typeof item.Value === 'undefined' ? 0 : item.Value} />
                                                </div>
                                            </td>
                                            <td><div>{(results[index] || {}).Comment}</div></td>
                                            <td className='normal' style={{ paddingRight: 10 }}>
                                            </td>
                                        </tr>
                                    </>

                                )
                            })}
                            <tr className='white-bg'>
                                <td className='normal'></td>
                                <td className='normal'></td>
                                <td></td>
                                <td className='normal'><div className='btn-con' style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={this.onCalculation}>Beräkna</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default TableForm;