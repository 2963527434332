import React, { PureComponent } from "react";
import '../styles/header.css'
import '../styles/commonStyles.css'

class Header extends PureComponent {
    state = {}
    render() {
        return (
            <div className='head-contain primary-border horArrange flexBetween'>
                <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="loading" className='logo-pic' />
                <div>
                    <p className='head-title'>Vallverktyget</p>
                    <p className="head-title" style={{ fontSize: '2rem' }}>gödslingsverktyg för tolkning av växtnäringsstatus i vall</p>
                </div>
                {/* <div className='send-contain horArrange'>
                    <img src={process.env.PUBLIC_URL + '/images/send.svg'} alt="loading" className='send-pic' />
                    <div className='send-title'>Skicka din beräkning</div>
                </div> */}
            </div>
        );
    }
}

export default Header;